<template>
	<div id="Replacement">
		<div class="Title ">
			<p>炼金合同</p>
		</div>

		<div class="Replacement_box">
			<div class="Replacement_item">
<!--				<i class="II"></i>-->
				<div class="Replacement_tit">
<!--					<p>饰品材料</p>-->
<!--					<div class="JG">-->
<!--						<img src="@/assets/images/mall/JiFen.png" alt="">-->
<!--						材料总价-->
<!--						<span>{{ZongJia.toFixed(2)}}</span>-->
<!--					</div>-->
				</div>

				<div class="Top_box">
					<div class="Top_list">
						<div class="Top_list-left" v-if="XZlist.length == 0">
							<img style="width: 70%" src="@/assets/images/heyue/qiang.png" alt="">
						</div>
						<div class="Top_item" v-for="(item,index) in XZlist" :key="index" @click="SCitem(item,index)">
							<span>{{item.dura_alias}}</span>
							<span>
								<img src="@/assets/images/mall/JiFen.png"
									 alt="">{{item.bean}}
							</span>
							<div class="Top_pic" :style="'background-image: url('+item.lv_bg_image+')'">
								<img :src="item.cover"
								 alt="">
							</div>
							<p>{{item.name}}</p>
						</div>
					</div>
				</div>


			</div>


			<div class="Q_center">
				<div class="yuan">
					<div class="Zhi" :style="sty">
						<div class="dian"></div>
					</div>
					{{Bili}}<span>%</span>
					<div class="dian"><i></i>
					</div>
					<div class="Q_btn" @click="postSynthesis">立即合成</div>
				</div>
<!--				<img src="@/assets/images/heyue/right.png" alt="">-->
<!--				<div class="Q_btn" @click="postSynthesis">确认升级</div>-->
			</div>


			<div class="Replacement_item">
				<i></i>
				<div class="Replacement_tit">
					<p></p>
					<div class="c_btn" style="cursor: pointer;" @click="gameshow = true">玩法说明</div>
				</div>

				<div class="Top_box">
					<div class="Top_list" style="white-space:pre-wrap">
						<div class="Top_item_t" @click="SCAditem">
							<div class="Top_list-left" v-if="!AdItem.cover">
								<img style="width: 70%" src="@/assets/images/heyue/qiang.png" alt="">
							</div>
							<div v-if="AdItem.cover" class="T_pic">
								<img :src="AdItem.cover"
								 alt="">
							</div>
							<div v-if="AdItem.cover" class="T_right">
								<span v-show="show"><img src="@/assets/images/mall/JiFen.png" alt="">{{AdItem.bean}}</span>
								<p>{{AdItem.name}}</p>
								<p style="font-size:12px">{{AdItem.dura_alias}}</p>
							</div>

						</div>
					</div>
				</div>


				<div class="PageNum">
					<el-pagination background layout="prev, pager, next" @current-change="Changepage" :page-size="Synthesis_page"
					 :total="SynthesisNum">
					</el-pagination>
				</div>
			</div>

			<van-overlay :show="isShow">
				<div class="wrapper" v-if="isShow">
					<div class="Show_box">
						<div class="Show_title">合成成功</div>
						<div class="Show_list">
							<div class="Show_item">
								<div class="Show_pic">
									<img :src="HCitem.cover" alt="">
								</div>
								<p>{{HCitem.name}}</p>
								<span><img src="@/assets/images/public/Gold.png" alt="">{{HCitem.bean}}</span>
							</div>
						</div>
						<div class="Show_btns">
							<van-button type="info" @click="GetIshow">继续合成</van-button>
						</div>
					</div>
				</div>
			</van-overlay>

			<van-overlay :show="netShow">
				<div class="wrapper">
					<div class="Show_box">
						<img class="Img" src="@/assets/images/heyue/ZZ.png" alt="">
						<div class="Show_btns">
							<van-button type="info" @click="GetIshow">继续合成</van-button>
						</div>
					</div>
				</div>
			</van-overlay>


			<!-- 玩法说明 -->
			<van-overlay :show="gameshow">
				<div class="wrapper">
					<div class="wrapper-box">
						<div class="wrapper-box-top"></div>
						<div class="cancel">
							<img src="@/assets/images/PublicImg/GuanBi.png" alt="" @click="gameshow = false">
						</div>
						<span>玩法说明</span>
						<p>1.选择一个目标饰品，用您的背包已有的饰品或购买我们提供的材料饰品进行升级。</p>
						<p>2.升级成功则直接获得目标饰品，升级失败则失去材料饰品。</p>
						<p>3.升级成功的概率是依据您提供材料饰品的价值而定的。</p>
					</div>
				</div>
			</van-overlay>
		</div>


		<div class="d-flex">
			<div class="b_box">
				<div class="b_box_nav">
					<p> 背包饰品：<span>共{{PageNum}}件</span></p>
					<p class="ml-1"> 已选：<span>{{XZlist.length}}件</span></p>
					<p class="ml-auto"> 已选价值：<span>{{ZongJia.toFixed(2)}}</span></p>
					<!--						<b>我的饰品</b>-->
					<!--						<div class="b_btn" @click="YiJian" style="cursor: pointer;">一键添加</div>-->
				</div>
				<div class="b_list">
					<div class="b_item" v-for="(item,index) in StorageList" :key="index" :style="'background-image: url('+item.lv_bg_image+')'" @click="XZitem(item,index)" ref="Item">
						<span><img src="@/assets/images/PublicImg/JinBi.png" alt="">{{item.bean}}</span>
						<div class="b_pic">
							<img :src="item.cover"
									 alt="">
						</div>
						<p>{{item.name}}</p>
					</div>
				</div>
				<div class="PageNum mt-1">
					<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :page-size="per_page"
												 :total="PageNum">
					</el-pagination>
				</div>
			</div>
			<div style="width: 30px"></div>
			<div class="b_box">
				<div class="b_box_nav">
					<b>饰品列表</b>
				</div>
				<div class="b_list">
					<div class="b_item" v-for="(item,index) in SynthesisList" :key="index" :style="'background-image: url('+item.lv_bg_image+')'" @click="ADitem(item,index)" ref="Add">

						<div class="d-flex  justify-content-around">
							<span>{{item.dura_alias}}</span>
						<span><img src="@/assets/images/PublicImg/JinBi.png" alt="">{{item.bean}}</span>
						</div>
						<div class="b_pic">
							<img :src="item.cover"
									 alt="">
						</div>
						<p>{{item.name}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Storage, Synthesis_list, Synthesis } from '@/network/api.js'
	export default {
		name: 'Replacement',
		data() {
			return {
				StorageList:[],
				PageNum:0,
				per_page:0,
				page:1,

				SynthesisList:[],
				SynthesisNum:0,
				Synthesis_page:0,
				S_page:1,

				XZlist:[],

				AdItem:{},
				show:false,

				ZongJia:0,
				Bili:0,

				HCitem:{},
				isShow:false,
				sty:'',
				gameshow:false,
				netShow:false,
				Z_key:false
			}
		},

		created() {
			this.GetStorage(),
			this.GetSynthesis_list()
		},

		methods:{
			//装备列表
			GetStorage(){
				Storage(this.page,'0').then((res) =>{
					// console.log(res.data.data)
					this.StorageList = res.data.data.data
					this.PageNum = Number(res.data.data.total)
					this.per_page = Number(res.data.data.per_page)
				})
			},
			//装备列表分页
			handleCurrentChange(val){
				// console.log(val)
				this.page = val
				this.GetStorage()
				setTimeout(()=>{
					this.StorageList.forEach((item,index)=>{
						this.$refs.Item[index].style.border = "1px solid #1e203e"
						this.XZlist.forEach(t=>{
							if(item.id == t.id){
								let key = "Select";
								let value = true
								item[key] = value
								this.$refs.Item[index].style.border = "1px solid #E9B10E"
							}
						})
				})
				},500)


				// setTimeout(() => {
				// 	for(let i = 0; i < this.StorageList.length; i++){
				// 		this.$refs.Item[i].style.border = "1px solid transparent"
				// 	}
				// },300)

			},
			//合成装备列表
			GetSynthesis_list(){
				Synthesis_list(this.S_page).then((res)=>{
					// console.log(res)
					// console.log(res.data.data.data)
					this.SynthesisList = res.data.data.data
					this.SynthesisNum = Number(res.data.data.total)
					this.Synthesis_page = Number(res.data.data.per_page)
				})
			},
			//合成装备列表分页
			Changepage(val){
				this.S_page = val
				this.GetSynthesis_list()
			},

			// 选中
			XZitem(item,index){
				console.log(item)
				if (item.Select) {
					delete item.Select
					this.$refs.Item[index].style.border = "1px solid #1e203e"
					let Index = this.XZlist.findIndex((v)=>{
						return v.id == item.id
					})
					// console.log(index)
					this.XZlist.splice(Index,1)
				} else {
					let key = "Select";
					let value = true
					item[key] = value
					this.$refs.Item[index].style.border = "1px solid #E9B10E"
					this.XZlist.push(item)
				}
			},
			//删除选中
			SCitem(item,index){
				// console.log(item)
				// this.XZlist.splice(index,1)
				let Index = this.StorageList.findIndex((v)=>{
					return v.id == item.id
				})
				// console.log(index)
				this.$refs.Item[Index].style.border = "1px solid #1e203e"
				// console.log(this.$refs.Item[Index])

			},

			// 选中
			ADitem(item,index){
				if (item.Select) {
					delete item.Select
					this.$refs.Add[index].style.border = "1px solid transparent"
					this.AdItem = {}
				} else {
					for(let i = 0; i<this.SynthesisList.length;i++){
						this.$refs.Add[i].style.border = "1px solid transparent"
						delete this.SynthesisList[i].Select
					}
					let key = "Select";
					let value = true
					item[key] = value
					this.$refs.Add[index].style.border = "1px solid #E9B10E"
					this.AdItem = item
					// console.log(item)
				}



			},

			//删除
			SCAditem(){
				let Index = this.SynthesisList.findIndex((v)=>{
					return v.id == this.AdItem.id
				})
				this.$refs.Add[Index].style.border = "1px solid transparent"
				this.AdItem = {}
			},

			//合成
			postSynthesis(){

				// console.log(this.XZlist)
				let AdArr = this.XZlist.filter((v) => {
					return v.dura_alias == '无'
				})
				// console.log(AdArr.length)    AdArr.length == 0:磨损度数组为0的 时候
				if(true){

					let data = []

					for(let i = 0 ; i<this.XZlist.length; i++){
						data.push({
							id:this.XZlist[i].id
						})
					}
					// console.log(data)

					Synthesis(data,this.AdItem.id).then((res)=>{
						this.sty = 'animation: zhuan 1s linear; animation-iteration-count:3;'
						// console.log(res)
						if(res.data.data){
							this.HCitem = res.data.data
							setTimeout(()=>{
								this.isShow = true
								this.XZlist = []
								this.GetStorage()
								this.AdItem = {}
								for(let i = 0; i < this.StorageList.length; i++){
									this.$refs.Item[i].style.border = "1px solid transparent"
								}
								for(let i = 0; i<this.SynthesisList.length;i++){
									this.$refs.Add[i].style.border = "1px solid transparent"
									delete this.SynthesisList[i].Select
								}
								this.sty = ''
								this.Bili = 0
							},3000)
						}else{
							setTimeout(()=>{
								this.netShow = true
								this.XZlist = []
								this.GetStorage()
								this.AdItem = {}
								for(let i = 0; i < this.StorageList.length; i++){
									this.$refs.Item[i].style.border = "1px solid transparent"
								}
								for(let i = 0; i<this.SynthesisList.length;i++){
									this.$refs.Add[i].style.border = "1px solid transparent"
									delete this.SynthesisList[i].Select
								}
								this.sty = ''
								this.Bili = 0
							},3000)
						}

					})
				}else{
					this.$message({
						message: '已选饰品磨损度不能为无!',
						type: 'error'
					})
				}

			},

			GetIshow(){
				this.isShow = false
				this.netShow = false
			},

			YiJian(){
				if(this.Z_key == false){
					let key = "Select";
					let value = true
					for(let i = 0; i < this.StorageList.length; i++){
						this.StorageList[i][key] = value
						this.$refs.Item[i].style.border = "1px solid #E9B10E"
						this.XZlist.push(this.StorageList[i])
					}
					this.Z_key = true
				}else{
					for(let i = 0; i < this.StorageList.length; i++){
						delete this.StorageList[i].Select
						this.$refs.Item[i].style.border = "1px solid transparent"
						this.XZlist = []
					}
					this.Z_key = false
				}


			}
		},
		watch:{
			AdItem(v){
				if(v.bean == undefined){
					this.show = false
				}else{
					this.show = true
					if(this.ZongJia != 0){
						let add = 0
						add =  (this.ZongJia/Number(v.bean)) * 100
						this.Bili = add.toFixed(0)
					}
				}
			},
			XZlist(v){
				this.ZongJia = 0
				// console.log(v)
				for(let i = 0; i<v.length;i++){
					this.ZongJia += Number(v[i].bean)
				}
			},
			ZongJia(v){
				if(this.AdItem.bean != undefined){
					let add = 0
					add =  (v/Number(this.AdItem.bean)) * 100
					this.Bili = add.toFixed(0)
				}

			},
		}
	}
</script>

<style lang="scss" scoped>
	.b_box {
		width: 100%;
		height: 4.4rem;
		background-color: #131426;
		padding: 10px 20px;
		/*background: url(../../assets/images/heyue/di.png) no-repeat center;*/
		background-size: contain;
		overflow: hidden;
		margin-top: 1.2rem;

		.b_box_nav {
			width: 100%;
			height: .4rem;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: .2rem;

			p {
				font-size: .16rem;
				color: #ccc;

				span {
					color: #f5953c;
				}
			}

			b {
				font-size: .22rem;
				color: #ccc;
				margin: 0 .7rem;
			}

			.b_btn {
				width: .76rem;
				height: .32rem;
				border-radius: .16rem;
				font-size: .16rem;
				background: #ffae00;
				color: #000;
				text-align: center;
				line-height: .32rem;
			}
		}

		.b_list {
			width: 100%;
			height: 2.8rem;
			margin: .1rem auto 0;
			display: flex;
			flex-wrap: wrap;
			overflow: auto;
			align-content: start;
			.b_item {
				width: 24%;
				border: 1px solid #1e203e;
				padding: 10px 0;
				overflow: hidden;
				margin-right: .04rem;
				margin-bottom: .05rem;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 80%;
				border-radius: .04rem;
				box-sizing: border-box;
				&:nth-child(5n) {
					margin-right: 0;
				}
				span{
					display: flex;
					justify-content: flex-end;
					margin-top: .05rem;
					img{
						width: .18rem;
					}
					font-size: .16rem;
					color: #ccc;
				}
				.b_pic{
					width: .7rem;
					height: .7rem;
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					margin: 0 auto;
					display: flex;
					justify-content: center;
					align-items: center;
					img{
						max-width: 100%;
						max-height: 100%;
					}
				}
				p{
					font-size: .12rem;
					padding: 0 .1rem;
					box-sizing: border-box;
					text-align: center;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}
	.Top_list-left{
		display: flex;
		justify-content: center;
		align-items: center;

	}
	.Replacement_box {
		padding-top: .6rem;
		display: flex;
		justify-content: space-between;
		background: url(../../assets/images/heyue/top.png) no-repeat top;
		background-size: 100%;
		.Replacement_item {
			width: 5.9rem;

			.II {
				display: block;
				width: 1.8rem;
				height: .1rem;
				background: url(../../assets/images/heyue/Zui.png) no-repeat center;
				background-size: contain;
			}

			.Replacement_tit {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: .4rem;

				p {
					font-size: .19rem;
					color: #fff;
				}
				.c_btn{
					width: .88rem;
					height: .38rem;
					border: 2px solid #ffae00;
					font-size: .14rem;
					text-align: center;
					line-height: .38rem;
					/*border-radius: .08rem;*/
					color: #ffae00;
				}
				.JG {
					display: flex;
					align-items: center;
					font-size: .16rem;
					color: #ccc;

					img {
						width: .16rem;
						height: .16rem;
						margin-right: .05rem;
					}

					span {
						font-size: .16rem;
						color: #f5953c;
						margin-top: .03rem;
						margin-left: .05rem;
					}
				}
			}
			.PageNum {
				width: 100%;
				text-align: center;
				margin-top: 20px;

				li {
					background: #111111 !important;

					&.active {
						background: #4854c9 !important;
					}

					&:hover {
						color: #fff !important;
					}
				}

				.btn-prev,
				.btn-next {
					background: #111111;
				}
			}
			.Top_box {
				width: 100%;
				height: 3rem;

				background-size: cover;
				display: flex;
				justify-content: center;
				align-items: center;

				.Top_list {
					width: 3.5rem;
					height: 3rem;
					display: flex;
					justify-content: center;
					align-items: center;
					background-repeat: no-repeat;
					background-position: center;
					background-image: url("../../assets/images/heyue/LR-bg.png");
					overflow-x: scroll;
					.Top_item {
						width: 1.1rem;
						display: inline-block;

						span {
							display: flex;
							width: 100%;
							justify-content: center;
							font-size: .16rem;
							margin-top: .1rem;

							img {
								width: .18rem;
								margin-bottom: .04rem;
								margin-right: .05rem;
							}
						}

						.Top_pic {
							width: 1rem;
							height: .9rem;
							background-position: center;
							background-repeat: no-repeat;
							background-size: contain;
							margin: .1rem auto;
							display: flex;
							justify-content: center;
							align-items: center;

							img {
								max-width: 100%;
								max-height: 100%;
							}
						}

						p {
							font-size: .16rem;
							padding: 0 .1rem;
							box-sizing: border-box;
							text-align: center;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;

						}
					}

					.Top_item_t{
						width: 100%;
						height: 100%;
						margin: 0 auto;
						display: flex;
						justify-content: center;
						align-items: center;
						padding-right: .1rem;
						box-sizing: border-box;

						.T_pic{
							width: 1.2rem;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-right: .2rem;
							img{
								max-width: 100%;
								max-height: 100%;
							}
						}
						.T_right{
							width: 1.6rem;
							span{
								font-size: .22rem;
								color: #f7953b;
								text-align: right;
								img{
									width: .2rem;
									margin-right: .05rem;
									margin-bottom: -.02rem;
								}
							}
							p{
								width: 1.5rem;
								font-size: .2rem;
								word-wrap:break-word;
							}
							img{
								width: 20px;
							}
						}
					}
				}

			}


		}



		.Q_center{
			width: 2rem;
			.yuan{
				width: 2.5rem;
				height: 2.5rem;
				background: url(../../assets/images/heyue/yuan.png) no-repeat center;
				background-size: contain;
				margin: .7rem auto 0 0;
				text-align: center;
				line-height: 2.5rem;
				font-size: .44rem;
				position: relative;

				.Zhi{
					width: .15rem;
					height: 100%;
					position: absolute;
					top: 0;
					left: 50%;
					margin-left: -0.075rem;
					padding-top: .14rem;
					box-sizing: border-box;
					.dian{
						width: .15rem;
						height: .15rem;
						border-radius: 50%;
						background-color: #7cdffe;
						box-shadow: 0 0 6px 3px #7cdffe;
					}
				}
				span{
					font-size: .22rem;
				}
			}
			img{
				display: block;
				margin: .8rem auto 0;
				width: 1rem;
			}
			.Q_btn{
				width: 2.5rem;
				height: .6rem;
				background-image: url("../../assets/images/OpenBox/btn.jpg");
				text-align: center;
				line-height: .6rem;
				font-size: .22rem;
				color: #fff;
				/*border-radius: .08rem;*/
				margin:10px auto 0 auto;
				cursor: pointer;
				&:hover{
					box-shadow: 0 0 8px #ffd939;
				}
			}
		}

		.Show_box {
			width: 500px;
			padding: 10px;
			box-sizing: border-box;
			border-radius: 10px;
			margin: 10px auto 0;
			position: relative;
			text-align: center;
			background: rgba($color: #fff, $alpha: .8);
			.Img{
				width: 60%;
			}
			.Show_title{
				text-align: center;
				font-size: 18px;
			}

			.Show_list {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				.Show_item {
					width: 150px;
					margin: 0 5px;
					margin-top: 10px;
					background: url(../../assets/images/public/back_pub.jpg) no-repeat center;
					background-size: cover;
					border-radius: 6px;
					border: 1px solid #E9B10E;
					box-sizing: border-box;
					cursor: pointer;

					.Show_pic {
						width: 120px;
						height: 120px;
						margin: 0 auto;
						background-image: url(http://www.gameskins.cn/img/LV4.9815642e.png);
						background-position: center;
						background-size: 80%;
						background-repeat: no-repeat;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							max-width: 90%;
							max-height: 90%;
							vertical-align: middle;
						}
					}

					p {
						width: 100%;
						padding: 0 5px;
						box-sizing: border-box;
						font-size: 14px;
						color: #fff;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					span {
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 16px;
						color: #75cd66;
						margin: 10px 0 10px;

						img {
							width: 20px;
							height: 20px;
							vertical-align: middle;
						}
					}
				}

			}

			.Show_btns {
				margin-top: 10px;

				.van-button {
					width: 160px;
					height: 40px;
					padding: 0;
					margin-right: 20px;
					font-size: 16px;
					background-color: #ffd322;
					color: #000;
					border: none;
					&:last-child {
						margin-right: 0;
					}

					.Gold {
						margin-left: 5px;
						color: #000;

						img {
							width: 18px;
							height: 18px;
							vertical-align: middle;
							margin-bottom: 2px;
							margin-right: 2px;
						}
					}
				}
			}
		}
		.wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
		.wrapper-box {
			.wrapper-box-top {
				width: 100%;
				height: 0.03rem;
				background: linear-gradient(90deg, transparent, rgba(72, 84, 201, .8), transparent);
			}

			width: 7.5rem;
			height: 2rem;
			position: relative;
			border: 1px solid #4854c9;
			background-color: #1e2355;
			border-radius: 0.08rem;
			overflow: hidden;
			padding: 0 0.35rem;
			box-sizing: border-box;

			span {
				display: block;
				text-align: center;
				font-size: 0.24rem;
				margin: 0.2rem 0 0.2rem;
			}

			p {
				font-size: 0.14rem;
				margin-bottom: 0.1rem;
				line-height: 1.75em;
			}

			.Tutorial {
				width: 100%;
				height: 100%;
				box-sizing: border-box;

				.title {
					width: 100%;
					height: 0.95rem;
					line-height: 0.95rem;
					text-align: center;
					font-size: 0.26rem;
				}

				.Tutorial-box {
					width: 100%;
					display: flex;
					justify-content: space-between;

					.JianTou {
						margin-top: 0.2rem;
					}

					.Tutorial-item {
						width: 1.6rem;
						text-align: center;

						.pic {
							width: 0.8rem;
							height: 0.8rem;
							margin: 0 auto;

							img {
								width: 100%;
								height: 100%;
							}
						}

						p {
							font-size: 0.16rem;
							color: #fff;
							margin-top: 0.1rem;
						}

						.info {
							color: rgba(255, 255, 255, 0.6);
							font-size: 0.14rem;
							line-height: 1.75em;
						}
					}
				}
			}

			.cancel {
				width: 0.5rem;
				height: 0.5rem;
				position: absolute;
				top: 0;
				right: 0;
				text-align: center;
				line-height: 0.5rem;

				img {
					width: 0.15rem;
					cursor: pointer;
				}
			}
		}
	}

	.Top_list::-webkit-scrollbar {
		width: 04rem;
		height: .03rem;
	}

	.Top_list::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: rgba(0, 0, 0, 0.2);
	}

	.Top_list::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: rgba(0, 0, 0, 0.1);

	}

	.b_list::-webkit-scrollbar {
		width: 4px;

	}

	.b_list::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: rgba(0, 0, 0, 0.2);
	}

	.b_list::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: rgba(0, 0, 0, 0.1);

	}


</style>
